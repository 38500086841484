<template>
    <div>
      <div class="text-center mb-2">
        <el-radio-group v-model="field.is_global_variable" >
          <el-radio-button :label="false">Custom Field</el-radio-button>
          <el-radio-button :label="true">Global Variable</el-radio-button>
        </el-radio-group>
      </div>
      <el-form label-position="right" v-if="!field.is_global_variable">
        <title-and-description :field="field" />
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
            <placeholder :field="field" />
          </el-col>
          <el-col :span="12">
            <!-- <field-filled-by :field="field" /> -->
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
          <!-- <is-field-required :field="field" class="field-required"/> -->
          <span class="info-text">
             *After confirming that you are not a robot, you will be granted access to this field for further verification*
            </span>
          </el-col>
          <el-col :span="12">
          </el-col>
        </el-row>
      </el-form>
  
      <el-form label-position="right" v-if="field.is_global_variable">
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
            <div class="form-group">
            
              <el-form-item label="Global Variable">
                <el-select v-model="field.global_variable_id" @change="setGlobalVariable">
                  <el-option
                    v-for="(globalVariable,index) of allGlobalVariables"
                    :key="index"
                    :label="globalVariable.label"
                    filterable
                    :value="globalVariable._id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
            <placeholder :field="field" />
          </el-col>
          <!-- <el-col :span="12">
            <field-filled-by :field="field" />
          </el-col>
        </el-row>
        <is-field-required :field="field" class="field-required"/> -->
      </el-row>
      <el-col :span="12">
            <span style="font-style: italic; color: #3366cc;">
             *After confirming you are not a robot, you will be granted access to this field for further verification*
            </span>
      </el-col>
      </el-form>
    </div>
  </template>
  
<script>
//import { mapGetters } from "vuex";
export default {
    components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    //IsFieldRequired: () => import("./IsFieldRequired"),
    //FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    //FieldAttributes: () => import("./FieldAttributes"),
  },
  props: ["field"]
}

</script>
<style scoped>
.info-text{
  font-style: italic; 
  color: #3366cc;
}
</style>